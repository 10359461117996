import { useEffect, useState } from "react";
import { Grid, Box, CircularProgress } from "@mui/material";
import { ChatUserCard } from "../../components/chat/cardData";
import { getCollectionData } from "../../firebase/getData";
import { Sidebar } from "../../components/Sidebar";

export const Chat = () => {
  const [chatArr, setChatArr] = useState([]);
  const [userArr, setUserArr] = useState([]);

  const getData = () => {
    getCollectionData("chats")
      .then((res) => setChatArr(res))
      .catch((err) => console.log(err));

    getCollectionData("users")
      .then((res) => setUserArr(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("Chat Data :", chatArr);
  console.log("User Data :", userArr);

  if (chatArr.length === 0 && userArr.length === 0) {
    return (
      <Sidebar>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <br />
      <Grid container spacing={2}>
        {chatArr &&
          chatArr.map((el) => {
            return (
              userArr &&
              userArr
                .filter((e) => e.id === el.id)
                .map((ele) => {
                  return (
                    <ChatUserCard
                      id={ele.id}
                      name={ele.name}
                      email={ele.email}
                      phone={ele.phone}
                      gender={ele.gender}
                    />
                  );
                })
            );
          })}
      </Grid>
    </Sidebar>
  );
};
