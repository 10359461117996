import { Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { CheckAuth } from "./auth/checkAuth";
import { LoginKeeper } from "./auth/loginKeeper";
import {
  Home,
  Chat,
  Login,
  Users,
  NotFound,
  Messages,
  ViewRoute,
  EditRoute,
  BusRoutes,
  HostDriver,
  CreateRoute,
  BookingReport,
} from "./pages";
import "./style.css";
import { CreateHostUserDriver } from "./pages/hostDriver/CreateHostUserDriver";
import { EditHostDriver } from "./pages/hostDriver/EditHostDriver";

export const Router = () => {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route element={<LoginKeeper />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<CheckAuth />}>
          <Route path="home" element={<Home />} />
          <Route path="chat" element={<Chat />} />
          <Route path="chat">
            <Route path="message/:id" element={<Messages />} />
          </Route>
          <Route path="users" element={<Users />} />
          <Route path="route" element={<BusRoutes />} />
          <Route path="route">
            <Route path="view-route/:id" element={<ViewRoute />} />
            <Route path="edit-route/:id" element={<EditRoute />} />
            <Route path="create-route" element={<CreateRoute />} />
          </Route>
          <Route path="host-driver" element={<HostDriver />} />
          <Route path="create-host-driver" element={<CreateHostUserDriver />} />
          <Route path="booking-report" element={<BookingReport />} />
          <Route path="edit-host-driver/:id" element={<EditHostDriver />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
