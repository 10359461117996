import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  CircularProgress,
} from "@mui/material";
import { UserTableData } from "../../components/users/tableData";
import { getCollectionData } from "../../firebase/getData";
import { Sidebar } from "../../components/Sidebar";

export const Users = () => {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    getCollectionData("users")
      .then((res) => setArr(res))
      .catch((err) => console.log(err));
  }, []);

  if (arr.length === 0) {
    return (
      <Sidebar>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>S.No.</b>
            </TableCell>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Phone</b>
            </TableCell>
            <TableCell>
              <b>E-Mail</b>
            </TableCell>
            <TableCell>
              <b>Gender</b>
            </TableCell>
            <TableCell>
              <b>Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arr &&
            arr.map((e, index) => {
              return (
                <TableRow key={e.id}>
                  <UserTableData
                    id={e.id}
                    name={e.name}
                    mail={e.email}
                    phone={e.phone}
                    index={index + 1}
                    gender={e.gender}
                  />
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Sidebar>
  );
};
