import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { allCities } from "../../data/cities";
import { UpdateContext } from "../../Context";
import {
  Box,
  Grid,
  Modal,
  Table,
  Button,
  Select,
  MenuItem,
  TableRow,
  TableBody,
  Container,
  TableHead,
  TableCell,
  TextField,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  Autocomplete,
  Checkbox,
  ListItemText,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Add, Delete, Edit } from "@mui/icons-material";
import { getCollectionData, getDocumentData } from "../../firebase/getData";

export let gData = {
  to: "",
  from: "",
  type: "",
  agency: "",
  category: "",
  busNumber: [],
};
let all_cities = [];

export const StepOne = () => {
  let [busNumbers, setBusNumbers] = useState([]);
  let [selectedBusNumber, setSelectedBusNumber] = useState([
    ...gData.busNumber,
  ]);

  function getAllBusNumber() {
    getCollectionData("allBusNumbers").then((e) => {
      setBusNumbers(e[0].busNumbers);
    });
  }

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedBusNumber(event.target.value);
    gData.busNumber = event.target.value;
    // gdata.busNumber =  event.target.value;
    setBusNumber(event.target.value);
  };

  useEffect(() => {
    getAllBusNumber();
    getAllCities();
  }, []);

  const navigate = useNavigate();
  const { NextStep } = UpdateContext();
  const { ResetStep } = UpdateContext();
  const [busNumber, setBusNumber] = useState([...gData.busNumber]);
  const [category, setCategory] = useState(gData.category);
  const [agency, setAgency] = useState(gData.agency);
  const [type, setType] = useState(gData.type);
  const [from, setFrom] = useState(gData.from);
  const [to, setTo] = useState(gData.to);
  const [cities, setCities] = useState([]);

  const handleCancel = () => {
    ResetStep();
    navigate("/route");
  };

  function getAllCities() {
    getDocumentData("AllCities", "AllCitiesList").then((res) => {
      setCities([...res.cities]);
      all_cities = [...res.cities];
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      to,
      from,
      type,
      agency,
      category,
      busNumber,
    };

    console.log(gData, "Get data from the");

    sessionStorage.setItem("step1", JSON.stringify(data));
    NextStep();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Autocomplete
            value={gData.from}
            onChange={(e, val) => {
              setFrom(val);
              gData.from = val;
            }}
            options={cities}
            renderInput={(params) => (
              <TextField
                {...params}
                label="From"
                placeholder="Enter Source"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            value={gData.to}
            options={cities}
            onChange={(e, val) => {
              setTo(val);
              gData.to = val;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="To"
                placeholder="Enter Destination"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            type="text"
            label="Travel Agency"
            placeholder="Enter Travels Name"
            value={gData.agency}
            onChange={(e) => {
              e.target.value == " "
                ? (e.target.value = "")
                : setAgency(e.target.value);
              gData.agency = e.target.value;
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              value={gData.category}
              onChange={(e) => {
                setCategory(e.target.value);
                gData.category = e.target.value;
              }}
              required
            >
              <MenuItem value="Economy">Economy</MenuItem>
              <MenuItem value="Affordable">Affordable</MenuItem>
              <MenuItem value="Premium">Premium</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6}>

          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Select Bus Number</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              sx={{ width: "100%" }}
              multiple
              value={selectedBusNumber}
              onChange={handleChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {busNumbers.map((busNumber, index) => (
                <MenuItem key={index} value={busNumber}>
                  <Checkbox
                    checked={selectedBusNumber.indexOf(busNumber) > -1}
                  />
                  <ListItemText primary={busNumber} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <TextField
            label="Bus Number"
            placeholder="Enter Bus Number"
            value={gData.busNumber}
            onChange={(e) => {
              e.target.value == ' ' ? e.target.value = '' : setBusNumber(e.target.value.toUpperCase())
              gData.busNumber = e.target.value
            }}
            fullWidth
            required
          /> */}

          {/* <Autocomplete
            value={gData.busNumber}
            options={busNumbers}
            onChange={(e, val) => {
              setBusNumber(val.toUpperCase());
              gData.busNumber = val;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Enter bus number"
                placeholder="Enter bus number"
                fullWidth
                // required
              />
            )}
          /> */}
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <InputLabel>Bus Type</InputLabel>
            <Select
              label="Bus Type"
              value={gData.type}
              onChange={(e) => {
                setType(e.target.value);

                gData.type = e.target.value;
              }}
              required
            >
              <MenuItem value="2 + 2">2 + 2</MenuItem>
              <MenuItem value="2 + 3">2 + 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <br />
      <Container>
        <Grid container spacing={4}>
          <Grid item md={2}></Grid>
          <Grid item md={4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              <b>Cancel</b>
            </Button>
          </Grid>
          <Grid item md={4}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              <b>Next</b>
            </Button>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
      </Container>
    </form>
  );
};

let data2 = {
  topStation: [],
  subStations: [],
  stationFare: [],
};

export const StepTwo = () => {
  const [val, setVal] = useState(0);
  const [eta, setEta] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [date, setDate] = useState('')
  const [fare, setFare] = useState("");
  const [station, setStation] = useState("");
  const [boarding, setBoarding] = useState("");
  const [modalOne, setModalOne] = useState(false);
  const [stationFare, setStationFare] = useState([...data2.stationFare]);
  const [modalTwo, setModalTwo] = useState(false);
  const [topStation, setTopStation] = useState([...data2.topStation]);
  const [subStations, setSubStations] = useState([...data2.subStations]);
  const { NextStep } = UpdateContext();
  const { previousStep } = UpdateContext();

  const handleCancel = () => {
    previousStep();
  };

  const handleSubmit = () => {
    if (topStation.length == 0) {
      alert("Please Add a Station");
      return;
    } else if (subStations.length == 0) {
      alert("Please Add Sub Station");
      return;
    }

    const data = {
      topStation,
      subStations,
      stationFare,
    };

    sessionStorage.setItem("step2", JSON.stringify(data));
    NextStep();
  };

  const handleStation = (e) => {
    e.preventDefault();

    data2.topStation.push(station);
    data2.stationFare.push({ station, fare });

    console.log(data2.stationFare);

    setTopStation([...topStation, station]);
    setStationFare([...stationFare, { station, fare }]);
    setModalOne(!modalOne);
    setStation("");
    setFare("");
  };

  const handleSubStations = (e) => {
    e.preventDefault();

    setSubStations([
      ...subStations,
      { name: topStation[val], boarding, lat, long, eta, day: date },
    ]);
    data2.subStations.push({ name: topStation[val], boarding, lat, long, eta, day: date });
    console.log(data2.subStations);

    setModalTwo(!modalTwo);
    setBoarding("");
    setLat("");
    setLong("");
    setEta("");
    setDate("")
  };

  function moveToDelete(e, index) {
    console.log(e, index);
    let arr = [...topStation];
    arr.splice(index, 1);
    setTopStation(arr);
    data2.topStation.splice(index, 1);

    data2.subStations = data2.subStations.filter((e2) => e2.name != e);
    setSubStations(data2.subStations);
    data2.stationFare = data2.stationFare.filter((e1) => e1.station != e);

    let arr2 = [...stationFare];

    arr2 = arr2.filter((e3) => e3.station != e);

    setStationFare(arr2);
  }

  function moveSubStationToDelete(e, index) {
    console.log(e, index);
    console.log(subStations);
    let arr2 = [...subStations];
    arr2.splice(arr2.indexOf(e), 1);
    setSubStations(arr2);
    data2.subStations.splice(data2.subStations.indexOf(e), 1);
  }

  return (
    <Container>
      <Button
        variant="text"
        color="primary"
        onClick={() => setModalOne(!modalOne)}
      >
        + Add Station
      </Button>

      <Modal open={modalOne} onClose={() => setModalOne(!modalOne)}>
        <Container
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: 5,
              width: "80%",
              borderRadius: 1,
              textAlign: "center",
              backgroundColor: "white",
            }}
          >
            <Typography>
              <b>Add Station Location</b>
            </Typography>
            <hr />
            <br />
            <form onSubmit={handleStation}>
              <Grid container spacing={4}>
                <Grid item md={6}>
                  <Autocomplete
                    value={station}
                    onChange={(e, val) => setStation(val)}
                    options={all_cities}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Station"
                        placeholder="Enter Station Location"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item md={6}>
                  <TextField
                    label="Fare"
                    type="number"
                    placeholder="Enter Fare Price"
                    value={fare}
                    onChange={(e) => setFare(e.target.value)}
                    required
                    fullWidth
                  />
                </Grid> */}
              </Grid>
              <br />
              <Grid container spacing={4}>
                <Grid item md={2}></Grid>
                <Grid item md={4}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setModalOne(!modalOne)}
                    fullWidth
                  >
                    <b>Cancel</b>
                  </Button>
                </Grid>
                <Grid item md={4}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    <b>Submit</b>
                  </Button>
                </Grid>
                <Grid item md={2}></Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Modal>
      <br />
      <br />
      {topStation &&
        topStation.map((e, index) => {
          return (
            <Box key={index}>
              <Grid container spacing={4}>
                <Grid item md={4}>
                  <b>{index + 1}.</b>
                </Grid>
                <Grid item md={4}>
                  <b>{e}</b>
                </Grid>
                <Grid item md={4}>
                  <IconButton
                    onClick={() => {
                      setVal(index);
                      setModalTwo(!modalTwo);
                    }}
                  >
                    <Add color="primary" />
                  </IconButton>
                  <IconButton>
                    <Delete
                      color="primary"
                      onClick={() => moveToDelete(e, index)}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <br />
              {subStations &&
                subStations
                  .filter((item) => e === item.name)
                  .map((el, inx) => {
                    return (
                      <Grid container spacing={4} key={inx}>
                        <Grid item md={4}></Grid>
                        <Grid item md={4}>
                          {el.boarding}
                        </Grid>
                        <Grid item md={4}>
                          {el.eta}

                          <IconButton>
                            <Delete
                              color="primary"
                              onClick={() => moveSubStationToDelete(el, inx)}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
              <hr />
            </Box>
          );
        })}
      <Modal open={modalTwo} onClose={() => setModalTwo(!modalTwo)}>
        <Container
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: 5,
              width: "80%",
              borderRadius: 1,
              textAlign: "center",
              backgroundColor: "white",
            }}
          >
            <Typography>
              <b>Select Boarding Points</b>
            </Typography>
            <hr />
            <br />
            <form onSubmit={handleSubStations}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <br />
                  <TextField
                    label="Boarding Point"
                    placeholder="Enter Boarding Point"
                    value={boarding}
                    onChange={(e) =>
                      e.target.value == " "
                        ? (e.target.value = "")
                        : setBoarding(e.target.value)
                    }
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6} sx={{ textAlign: "left" }}>
                  <Typography sx={{ mb: 0.25 }}>Choose Time</Typography>
                  <TextField
                    type="time"
                    value={eta}
                    onChange={(e) => setEta(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    type="number"
                    label="Latitude"
                    placeholder="Enter Latitude"
                    value={lat}
                    onChange={(e) => setLat(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    type="number"
                    label="Longitude"
                    placeholder="Enter Longitude"
                    value={long}
                    onChange={(e) => setLong(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item md={6}>
                  {/* <TextField
                    type="date"
                    fullWidth
onChange={(e)=>setDate(e.target.value)}
                    required
                  /> */}
                  <Select
                    fullWidth
                    onChange={(e) => setDate(e.target.value)}
                    value={date}
                  >
                    <MenuItem value='Day 1'>Day 1</MenuItem>
                    <MenuItem value='Day 2'>Day 2</MenuItem>
                    <MenuItem value='Day 3'>Day 3</MenuItem>
                  </Select>



                </Grid>
              </Grid>
              <br />
              <Grid container spacing={4}>
                <Grid item md={2}></Grid>
                <Grid item md={4}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setModalTwo(!modalTwo)}
                    fullWidth
                  >
                    <b>Cancel</b>
                  </Button>
                </Grid>
                <Grid item md={4}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    <b>Submit</b>
                  </Button>
                </Grid>
                <Grid item md={2}></Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Modal>
      <br />
      <br />
      <Grid container spacing={4}>
        <Grid item md={2}></Grid>
        <Grid item md={4}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleCancel}
            fullWidth
          >
            <b>Previous</b>
          </Button>
        </Grid>
        <Grid item md={4}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            fullWidth
          >
            <b>Next</b>
          </Button>
        </Grid>
        <Grid item md={2}></Grid>
      </Grid>
    </Container>
  );
};
let thirdPagePrefilledData = { routes: [], routesWithFare: [] };

export const StepThree = ({ submitfun }) => {
  const navigate = useNavigate();
  const { ResetStep } = UpdateContext();
  const [step1, setStep1] = useState({});
  const [step2, setStep2] = useState({});
  const { previousStep } = UpdateContext();
  let [showInput, setShowInput] = useState(false);
  let [data, setData] = useState({ from: "", to: "", fare: "" });

  const [thirdPageData, setThirdPageData] = useState({
    routes: thirdPagePrefilledData.routes,
    routesWithFare: thirdPagePrefilledData.routesWithFare,
  });

  const handleCancel = () => {
    if (thirdPageData.routes.length != 0) {


      gData = {
        to: "",
        from: "",
        type: "",
        agency: "",
        category: "",
        busNumber: "",
      };

      data2 = {
        topStation: [],
        subStations: [],
        stationFare: [],
      };

      submitfun(thirdPageData);
      setThirdPageData({ routes: [], routesWithFare: [] });
      ResetStep();
      navigate("/route");
    }
    else {
      alert('add routes first')
    }
  };

  const getData = () => {
    setStep1(JSON.parse(sessionStorage.getItem("step1")));
    setStep2(JSON.parse(sessionStorage.getItem("step2")));

    console.log(JSON.parse(sessionStorage.getItem("step2")));
  };
  function moveBack() {
    previousStep();
  }

  useEffect(() => {
    getData();
  }, []);

  function moveToDelete(index) {
    let arr = { ...thirdPageData };
    arr.routes.splice(index, 1);
    arr.routesWithFare.splice(index, 1);

    thirdPagePrefilledData = { ...arr };
    setThirdPageData(arr);
  }

  function submitData() {
    console.log(data);
    setThirdPageData({
      ...thirdPageData,
      routes: [...thirdPageData.routes, `${data.from}-${data.to}`],
      routesWithFare: [
        ...thirdPageData.routesWithFare,
        { destination: `${data.from}-${data.to}`, fare: data.fare },
      ],
    });
    setShowInput(false);
    setData({ from: "", to: "", fare: "" });

    thirdPagePrefilledData = {
      ...thirdPagePrefilledData,
      routes: [...thirdPagePrefilledData.routes, `${data.from}-${data.to}`],
      routesWithFare: [
        ...thirdPagePrefilledData.routesWithFare,
        { destination: `${data.from}-${data.to}`, fare: data.fare },
      ],
    };
    console.log(thirdPagePrefilledData);
  }

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>S.No.</b>
            </TableCell>
            <TableCell>
              <b>Journey</b>
            </TableCell>
            <TableCell>
              <b>Fare</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {thirdPageData.routesWithFare &&
            thirdPageData.routesWithFare.map((e, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}.</TableCell>
                  <TableCell>
                    <Typography textTransform="capitalize">
                      {e.destination}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{e.fare}</Typography>
                  </TableCell>
                  <TableCell>
                    <Delete
                      sx={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() => moveToDelete(index)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>

      {!showInput ? (
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowInput(true)}
        >
          + Add Station
        </Button>
      ) : (
        <form onSubmit={submitData}>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            spacing={1}
          >
            <Grid item md={3}>
              <Autocomplete
                options={all_cities}
                defaultValue={data.from}
                onChange={(e, val) => setData({ ...data, from: val })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="From"
                    placeholder="Enter Source"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <Autocomplete
                options={all_cities}
                defaultValue={data.to}
                onChange={(e, val) => setData({ ...data, to: val })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="To"
                    placeholder="Enter Source"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item md={3}>
              <TextField
                defaultValue={data.fare}
                label="Fair"
                type="number"
                onChange={(e) => setData({ ...data, fare: e.target.value })}
                fullWidth
                required
              />
            </Grid>
            <Grid item md={3}>
              <Button variant="contained" type='submit'>
                Save
              </Button>
              <Button onClick={() => setShowInput(false)}>Cancel</Button>
            </Grid>
          </Grid>
        </form>
      )}
      <br />
      <br />

      {/* this is button container  */}
      <Grid container spacing={10} sx={{ justifyContent: "center" }}>
        <Grid item lg={4}>
          <Button
            color="secondary"
            variant="contained"
            onClick={moveBack}
            fullWidth
          >
            <b>Previous</b>
          </Button>
        </Grid>
        <Grid item lg={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancel}
            fullWidth
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
