import { Link } from "react-router-dom";
import { Typography, Grid, Box, Button } from "@mui/material";

export const ChatUserCard = ({ id, name, email, phone, gender }) => {
  return (
    <Grid item md={4} key={id}>
      <Box
        sx={{
          p: 2.5,
          boxShadow: 3,
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <Typography>
          <b>{name}</b>
        </Typography>
        <Typography>{email}</Typography>
        <br />
        <hr />
        <br />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            <b>Gender</b>
          </Typography>
          <Typography>{gender}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>
            <b>Phone</b>
          </Typography>
          <Typography>{phone}</Typography>
        </Box>
        <br />
        <Link to={`./message/${id}`}>
          <Button variant="outlined" color="primary" fullWidth>
            <b>V I E W</b>
          </Button>
        </Link>
      </Box>
    </Grid>
  );
};
