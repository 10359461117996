import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  Firestore,
  onSnapshot,
  snapshotEqual,
  where,
} from "firebase/firestore";
import { db } from "./firebase";

// Get all the data from a collection
export const getCollectionData = async (collectionName) => {
  const arr = [];
  const querySnapshot = await getDocs(collection(db, collectionName));
  
  querySnapshot.forEach((doc) => {
    arr.push({ id: doc.id, ...doc.data() });
  });

  return arr;
};

// Get data from a Single Document in a Collection
export const getDocumentData = async (collectionName, documentName) => {
  const docRef = doc(db, collectionName, documentName);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No such document!");
  }
};
export const getSnapShotData = async (collectionName, id) => {
    onSnapshot(doc(db, collectionName, id),(snapShot) => {
    return snapShot.data();
  });
};
