import { getCollectionData } from "../../firebase/getData";
import { TopCard } from "../../components/TopCard";
import { Sidebar } from "../../components/Sidebar";
import { Grid, Container } from "@mui/material";
import chartOptions from "./chartOptions.json";
import { useEffect, useState } from "react";
import chartData from "./chartData.json";
import { Line } from "react-chartjs-2";
import { CloseFullscreenOutlined } from "@mui/icons-material";

export const Home = () => {
  let [userData, setUserData] = useState([]);
  let [hostData, setHostData] = useState([]);
  let [bookingData, setBookingData] = useState([]);
  let [routeData, setRouteData] = useState([]);


console.log(userData.filter(e=>{
 let date = new Date().getDate()
 let month = new Date().getMonth()+1
let year = new Date().getFullYear()
let gg = date+'-'+month+'-'+year

return e.date == gg
}).length)

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getCollectionData("users")
      .then((e) => setUserData(e))
      .catch((err) => console.log(err));

    getCollectionData("hostUserDriver")
      .then((e) => setHostData(e))
      .catch((err) => console.log(err));

      getCollectionData("bookings")
      .then((e) => setBookingData(e))
      .catch((err) => console.log(err));

      getCollectionData("routes")
      .then((e) => setRouteData(e))
      .catch((err) => console.log(err));
  };
  
 
  console.log()

  return (
    <Sidebar>
      <Grid container spacing={3} textAlign="center" sx={{my:2}}>
        <Grid item md={3}>
          <TopCard heading="Users" text={userData.length} />
        </Grid>
        <Grid item md={3}>
          <TopCard heading="Host Users" text={hostData.filter(e=>e.isActive).length} />
        </Grid>
        <Grid item md={3}>
          <TopCard heading="Active Routes" text={routeData.filter(e=>e.isActive).length} />
        </Grid>
        <Grid item md={3}>
          <TopCard heading="Bookings" text={bookingData.length} />
        </Grid>
        <Grid item md={3}>
          <TopCard heading="Daily Register User" text={userData.filter(e=>{
 let date = new Date().getDate()
 let month = new Date().getMonth()+1
let year = new Date().getFullYear()
let gg = date+'-'+month+'-'+year

return e.date == gg
}).length} />
        </Grid>
      </Grid>
      <Container maxWidth="md" sx={{ mt: 10 }}>
        <Line data={chartData} options={chartOptions} />
      </Container>
    </Sidebar>
  );
};
