import { Box, Typography } from "@mui/material";
import { Sidebar } from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { db } from "../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useState } from "react";
import { updateDocument } from "../../firebase/setData";

export const Messages = () => {
  const { id } = useParams();
let [messages,setMessages]=useState([]);
let [userData,setUserData]=useState([]);
const bottomRef = useRef(null);

let [newMessage,setNewMessage]=useState({content:'',sender:'Admin'})
  useEffect(() => {
    getData();
    getUserDaat();
  }, []);


  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  const handleKeypress = e => {
    if (e.code  === 'Enter') {
      sendMessage();
    }
  };




 




  async function getUserDaat() {
    onSnapshot(doc(db, 'users', id), (snapShot) => {
      setUserData(snapShot.data().name)
      console.log("okokkok",snapShot.data().name)
    })
 }


  async function getData() {
     onSnapshot(doc(db, 'chats', id), (snapShot) => {
       setMessages(snapShot.data().msg)
       console.log("okokkok",snapShot.data().msg)
     })
  }
  function sendMessage(){
  
   
    if(newMessage.content!=''){
    updateDocument('chats',id,{msg:[...messages,newMessage]})
    setNewMessage({content:'',sender:'Admin'})
    }
  }
  return (
    <Sidebar>
      {/* <Typography>Messages Works 😊</Typography>
      <br />
      <Typography>
        ID : <b>{id}</b>
      </Typography> */}

      <Typography>
        <div style={{width:"77.6%", padding:"10px",borderRadius:"8px", border:"1px solid #D6D6D6", position:"fixed", backgroundColor:"white",fontSize:"20px"}} >

        <b>{userData}</b>

        </div>
      </Typography>




<div className="message_container"  >
  {messages.map((e)=>{
    if(e.sender=='User'){
      return(
        <div className="chatSetup" style={{alignSelf:'flex-start',backgroundColor:"#002E6E"}}>{e.content}</div>
        )
      }
      else{
        return(
          <div className="chatSetup" style={{alignSelf:"flex-end", backgroundColor:"#7CB3FF" }}>{e.content}</div>
          )
        }
      })}
     
</div>
<div ref={bottomRef} />

<div className="navbar" >

  
<input type='text' className="inputClass" value={newMessage.content}  onKeyPress={handleKeypress} onChange={(e)=> e.target.value== " "?e.target.value="" : setNewMessage({...newMessage,content:e.target.value})}/>
<button className="buttonData" onClick={sendMessage}> Send</button>

</div>  

 
  </Sidebar>
  );
};
