import {
  Box,
  Grid,
  Alert,
  Button,
  Select,
  MenuItem,
  Checkbox,
  Snackbar,
  Container,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  ListItemText,
  Autocomplete,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { addDocument, createDocument } from "../../firebase/setData";
import { Link } from "react-router-dom";
import { getCollectionData } from "../../firebase/getData";

export const CreateHostUserDriver = () => {
  const id = uuidv4();

  let emailRegex = /^\S+@\S+\.\S+$/;
  let passwordregex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~"]{8,16}$/;

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;

  let [submit, setSubmit] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [userDriverData, setUserDriverData] = useState({});
  let [passwordError, setPasswordError] = useState(false);
  let [successMessage, setSuccessMessage] = useState(false);
  let [selectedBusNumber, setSelectedBusNumber] = useState([]);
  let [passwordMatchError, setPasswordMatchError] = useState(false);

  let [busNumbers, setBusNumbers] = useState([]);
  let [allBusOperators, setAllBusOperators] = useState([]);

  function getAllBusNumber() {
    getCollectionData("allBusNumbers").then((e) => {
      setBusNumbers(e[0].busNumbers);
    });
  }

  async function createHostData(e) {
    e.preventDefault();
    if (!emailError && !passwordError) {
      if (userDriverData.password == userDriverData.confirmPassword) {
        setSubmit(true);
        setPasswordMatchError(false);
        await createDocument("hostUserDriver", id, {
          id,
          ...userDriverData,
          ["isActive"]: true,
          busNumber: selectedBusNumber,
          loggedIn:false
        });
        setSelectedBusNumber([]);
        setSubmit(false);
        setSuccessMessage(true);
      } else {
        setPasswordMatchError(true);
      }
    }
  }

  useEffect(() => {
    getAllBusNumber();
    getAllBusOperator();
  }, []);

  function getAllBusOperator() {
    getCollectionData("bus Operators").then((res) => {
      console.log(res);
      var operatorName = res.map((e) => {
        return e.name;
      });
      setAllBusOperators(operatorName);
    });
  }

  const handleChange = (event) => {
    setSelectedBusNumber(event.target.value);
  };

  if (!submit) {
    return (
      <>
        <Sidebar>
          <Typography variant="h4" sx={{ textAlign: "center", m: 4 }}>
            Create Host
          </Typography>
          <Box
            sx={{ width: "80%", margin: "auto" }}
            component="form"
            onSubmit={createHostData}
            Validate
          >
            <Grid container sx={{ alignItems: "center" }} spacing={2}>
              <Grid item md={4}>
                <label>
                  <b>
                    Host Name<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <input
                  type="text"
                  required
                  placeholder="Host Name"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setUserDriverData({
                          ...userDriverData,
                          ["hostName"]: e.target.value.trim(),
                        });
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <label>
                  <b>
                    Phone Number<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <input
                  type="number"
                  required
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setUserDriverData({
                          ...userDriverData,
                          ["phoneNo"]: e.target.value.trim(),
                        });
                  }}
                />
              </Grid>

              <Grid item md={4}>
                <label>
                  <b>
                    Email<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <input
                  type="email"
                  placeholder="email"
                  required
                  onChange={(e) => {
                    e.target.value.match(emailRegex) == null
                      ? setEmailError(true)
                      : setEmailError(false);
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setUserDriverData({
                          ...userDriverData,
                          ["email"]: e.target.value,
                        });
                  }}
                />
                {emailError ? (
                  <div className="red_clr">
                    Please insert a valid email address
                  </div>
                ) : null}
              </Grid>
              <Grid item md={4}>
                <label>
                  <b>
                    Bus Number<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Select Bus Number
                  </InputLabel>
                  <Select
                    sx={{ width: "100%" }}
                    multiple
                    value={selectedBusNumber}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {busNumbers.map((busNumber, index) => (
                      <MenuItem key={index} value={busNumber}>
                        <Checkbox
                          checked={selectedBusNumber.indexOf(busNumber) > -1}
                        />
                        <ListItemText primary={busNumber} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <label>
                  <b>
                    Bus Type<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <Select
                  type="text"
                  required
                  fullWidth
                  label="select bus type"
                  onChange={(e) => {
                    setUserDriverData({
                      ...userDriverData,
                      ["busType"]: e.target.value,
                    });
                  }}
                >
                  <MenuItem value="2 + 2">2 + 2</MenuItem>
                  <MenuItem value="2 + 3">2 + 3</MenuItem>
                </Select>
              </Grid>
              <Grid item md={4}>
                <label>
                  <b>
                    Bus Operator Name<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  onChange={(e, val) => {
                    setUserDriverData({
                      ...userDriverData,
                      ["busOperatorName"]: val,
                    });
                  }}
                  options={allBusOperators}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Operator"
                      placeholder="Select Operator Name"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <label>
                  <b>
                    Password<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <input
                  type="text"
                  placeholder="Password"
                  required
                  onChange={(e) => {
                    e.target.value.match(passwordregex) == null
                      ? setPasswordError(true)
                      : setPasswordError(false);
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setUserDriverData({
                          ...userDriverData,
                          ["password"]: e.target.value,
                        });
                  }}
                />
                {passwordError ? (
                  <div className="red_clr">
                    Minimum length of password should be 8 character Password
                    must contain: At least one UPPERCASE, one lowercase, one
                    number, one special character(!,@,#,$)
                  </div>
                ) : null}
              </Grid>
              <Grid item md={4}>
                <label>
                  <b>
                    Confirm Password<sup>*</sup>:
                  </b>
                </label>
              </Grid>
              <Grid item md={6}>
                <input
                  type="text"
                  required
                  placeholder="confirm password"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setUserDriverData({
                          ...userDriverData,
                          ["confirmPassword"]: e.target.value,
                        });
                  }}
                />
                {passwordMatchError ? (
                  <div className="red_clr">
                    The Password confirmation doesn't match
                  </div>
                ) : null}
              </Grid>
            </Grid>
            <Container maxWidth="xs" sx={{ mt: 5 }}>
              <Grid container spacing={2}>
                <Grid item md={1}></Grid>
                <Grid item md={5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item md={5}>
                  <Link to="/host-driver">
                    <Button variant="contained" color="secondary" fullWidth>
                      <b>Cancel</b>
                    </Button>
                  </Link>
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </Container>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={successMessage}
            autoHideDuration={3000}
            onClose={() => setSuccessMessage(false)}
          >
            <Alert
              onClose={() => setSuccessMessage(false)}
              severity="success"
              sx={{ width: "100%", backgroundColor: "green", color: "white" }}
            >
              Host Added Successfully!
            </Alert>
          </Snackbar>
        </Sidebar>
      </>
    );
  } else {
    return (
      <Sidebar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "70vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Sidebar>
    );
  }
};
