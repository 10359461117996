import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  List,
  Drawer,
  AppBar,
  Toolbar,
  ListItem,
  Container,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import {
  Map,
  Home,
  Chat,
  Group,
  Person,
  Plagiarism,
} from "@mui/icons-material";

export const Sidebar = ({ children }) => {
  let navigate = useNavigate()
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" sx={{ width: "calc(100% - 250px)" }}>
          <Toolbar>
            <Typography sx={{ flexGrow: 1 }}>BUS TM Dashboard</Typography>
            <Link
              to={"/"}
              onClick={() => sessionStorage.setItem("isLoggedIn", false)}
            >
              <b>LOGOUT</b>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: 250,
            "& .MuiDrawer-paper": {
              width: 250,
            },
          }}
        >
        
          
          <img src="/images/logo.png" alt="Logo" onClick={()=>navigate('/home')} style={{ margin: 20,width:'70%' , cursor:"pointer"}}/>
          
          <List>
            <FullListItem
              icon={<Home color="primary" />}
              text="Home"
              link="/home"
            />
            <FullListItem
              icon={<Person color="primary" />}
              text="Users"
              link="/users"
            />
            <FullListItem
              icon={<Map color="primary" />}
              text="Routes"
              link="/route"
            />
            <FullListItem
              icon={<Chat color="primary" />}
              text="Chats"
              link="/chat"
            />
            <FullListItem
              icon={<Plagiarism color="primary" />}
              text="Booking Report"
              link="/booking-report"
            />
            <FullListItem
              icon={<Group color="primary" />}
              text="Host"
              link="/host-driver"
            />
          </List>
        </Drawer>
        <Container maxWidth="xl" sx={{ mt: 10, marginTop:"4.7%" }}>
          {children}
        </Container>
      </Box>
    </>
  );
};

const FullListItem = ({ text, icon, link }) => {
  const navigate = useNavigate();

  return (
    <>
      <ListItem>
        <ListItemButton onClick={() => navigate(`${link}`)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            <b>{text}</b>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </>
  );
};
