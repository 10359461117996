import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { getDocumentData } from "../../firebase/getData";
import { createDocument, updateDocument } from "../../firebase/setData";

export const AddNewCity = () => {
  let [addCityDialog, setAddCityDialog] = useState(false);
  let [cityName, setCityName] = useState("");
  let [allCities, setAllCities] = useState([]);

  useEffect(() => {
    getAllCities();
  }, []);

  function addCity(e) {
    e.preventDefault();
    let num = 0;
    for (let i = 0; i < allCities.length; i++) {
      if (allCities[i].toLowerCase().trim() == cityName.toLowerCase().trim()) {
        console.log("already exist");
        num++;
        break;
      }
    }

    if (num == 0) {
      if (allCities.length == 0) {

        let rr = cityName.toLowerCase().trim().split("");
        rr[0] = rr[0].toUpperCase();
        let gg = rr.join("");

        createDocument("AllCities", "AllCitiesList", {
          cities: [gg.trim()],
        });
        getAllCities();
        setCityName("");
    setAddCityDialog(false);

      } else {
        let rr = cityName.toLowerCase().trim().split("");
        rr[0] = rr[0].toUpperCase();
        let gg = rr.join("");

        updateDocument("AllCities", "AllCitiesList", {
          cities: [...allCities, gg.trim()]
        });
        console.log("i am update")
        getAllCities();
        setCityName("");
    setAddCityDialog(false);

      }
    } else {
      alert("city is already exist");
      setCityName("");
      
    }

  }

  function getAllCities() {
    getDocumentData("AllCities", "AllCitiesList").then((res) => {
      //   console.log(res);
      if (res != undefined) {
        setAllCities([...res.cities]);
      }
    });
  }
  function moveToDeleteCity(e, index) {
    let arr = [...allCities];
    arr.splice(index, 1);
    setAllCities(arr);

    updateDocument("AllCities", "AllCitiesList", {
      cities: arr,
    });
  }
  return (
    <>
      <Button
        onClick={() => setAddCityDialog(true)}
        sx={{ m: 2 }}
        variant="contained"
        color="primary"
      >
        + Add City
      </Button>

      <Dialog open={addCityDialog}
      fullWidth={true}
      maxWidth={'sm'}
      >
        <Box sx={{ height: "100vh" }}>
          <form onSubmit={addCity}>
            <Box sx={{ p: 3 }}>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Add City
              </Typography>
              <TextField
                onChange={(e) =>e.target.value==" "?e.target.value="":setCityName(e.target.value)}
                value={cityName}
                required
                label="Type City"
                sx={{ m: 3, width: "90%" }}
              />
              <br />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => setAddCityDialog(false)} sx={{ mx: 2 }}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Add City
                </Button>
              </Box>
            </Box>
          </form>

          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {" "}
            All Cities
          </Typography>
          <Box sx={{ height: "50vh", overflowY: "scroll", px: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Sno.</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allCities &&
                  allCities.map((e, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>{e}</TableCell>
                        <TableCell>
                          <Delete
                            onClick={() => moveToDeleteCity(e, index)}
                            sx={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
