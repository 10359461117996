import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDUAXRkHybk6RfNJHR30ukC0VfiAEK4T4s",
  authDomain: "bus-tm-5f04a.firebaseapp.com",
  projectId: "bus-tm-5f04a",
  storageBucket: "bus-tm-5f04a.appspot.com",
  messagingSenderId: "675886709932",
  appId: "1:675886709932:web:69ff9c8722785dc1af7309",
  measurementId: "G-7QSG30466E",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
