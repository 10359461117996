import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { getCollectionData } from "../../firebase/getData";
import { deleteDocument } from "../../firebase/deleteData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Switch from "@mui/material/Switch";
import { async } from "@firebase/util";
import { updateDocument } from "../../firebase/setData";

export const HostDriver = () => {
  let [allData, setAllData] = useState([]);
  let [noData, setNoData] = useState(false);
  let [deleteDialog, setDeleteDialog] = useState(false);
  let [delId, setDelId] = useState("");
  let [deletedIndex , setDeletedIndex] = useState("")

  useEffect((e) => {
    getAll();
  }, []);
  // starts here

  const [checked, setChecked] = useState("");
  // const toggleChecked = () => setChecked(value => !value);

  const toggleChecked = (event, id,index) => {
   
    let newArr = [...allData]
   
newArr[index].isActive = event.target.checked
setAllData(newArr)
    updateDocument("hostUserDriver", id, {
      isActive: event.target.checked,
    })
      .then(() => {
      
      })
      .catch((err) => {
        alert(err);
      });
  };
  // console.log(checked);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // ends here

  function moveToDelete(id, index) {
    setDeleteDialog(true);
    setDelId(id);
    setDeletedIndex(index)
  }

  async function deletHostDriverData() {
  let arr = [...allData]

  arr.splice(deletedIndex,1)
  setAllData(arr)
    setDeleteDialog(false);

    await deleteDocument("hostUserDriver", delId)
      .catch((err) => console.log(err));
  }

  async function getAll() {
    await getCollectionData("hostUserDriver").then((e) => {
      setAllData(e);
      e.length == 0 ? setNoData(true) : null;
    });
  }

  if (allData.length == 0) {
    return (
      <Sidebar>
        <Box sx={{ my: 4 }}>
          <Link to="/create-host-driver">
            <Button variant="contained">Create Host User +</Button>
          </Link>
        </Box>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {noData ? <div>No Data Available</div> : <CircularProgress />}
        </Box>
      </Sidebar>
    );
  }
  return (
    <Sidebar>
      <Box sx={{ my: 4 }}>
        <Link to="/create-host-driver">
          <Button variant="contained">Create Host User +</Button>
        </Link>
      </Box>
      <Dialog open={deleteDialog}>
        <Typography sx={{ p: 5 }}>Are you sure want to delete ?</Typography>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button autoFocus onClick={() => setDeleteDialog(false)}>
            Cancel
          </Button>
          <button
            className="btn-primary"
            onClick={deletHostDriverData}
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>

      {/* table starts here  */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Host Name</b>
              </TableCell>
              <TableCell>
                <b>Operator Name</b>
              </TableCell>
              <TableCell>
                <b>Bus no.</b>
              </TableCell>
              <TableCell>
                <b>Phone</b>
              </TableCell>
              <TableCell>
                <b>Bus Type</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? allData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : allData
            ).map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.hostName}
                </TableCell>
                <TableCell>{row.busOperatorName}</TableCell>
                <TableCell>{row.busNumber.map((bus,index2)=> <span> {bus} {index2 != row.busNumber.length-1?',':null}</span> )}</TableCell>
                <TableCell>{row.phoneNo}</TableCell>
                <TableCell>{row.busType}</TableCell>
                <TableCell>
                <div>{row.isActive?<span style={{color:"#1fd655"}}>Active</span>:<span style={{color:'red'}} >Inactive</span>}</div>
                  <Switch
                    checked={row.isActive}
                    onChange={(event) => toggleChecked(event, row.id,index)}
                  />
                </TableCell>
                <TableCell>
                  <Link to={`/edit-host-driver/${row.id}`}>
                    <EditIcon />
                  </Link>
                  <DeleteIcon
                    onClick={() => moveToDelete(row.id, index)}
                    sx={{ cursor: "pointer" }}
                  />
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={allData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Sidebar>
  );
};
