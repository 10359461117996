import { v4 as uuidv4 } from "uuid";
import { ReadContext } from "../../Context";
import { Sidebar } from "../../components/Sidebar";
import { createDocument } from "../../firebase/setData";
import { StepOne, StepTwo, StepThree } from "../../components/busRoutes/Steps";
import { Step, StepLabel, Stepper, Container } from "@mui/material";

export const CreateRoute = () => {
  const { step } = ReadContext();

  const id = uuidv4();

  const changeForm = () => {
    switch (step) {
      case 1:
        return <StepOne />;

      case 2:
        return <StepTwo />;

      case 3:
        return <StepThree submitfun={submitForm} />;
    }
  };

  // if (step > 2) {
  function submitForm(thirdPageData) {
    const step1 = JSON.parse(sessionStorage.getItem("step1"));
    const step2 = JSON.parse(sessionStorage.getItem("step2"));

    const data = {
      id,
      ...step1,
      ...step2,
      routes: thirdPageData.routes,
      routesWithFare: thirdPageData.routesWithFare,
    };
    console.log(data);

    createDocument("routes", id, { ...data, ["isActive"]: true })
      .then(() => console.log("Data Added"))
      .catch((err) => console.log(err));
  }

  return (
    <Sidebar>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <Stepper activeStep={step}>
          <Step>
            <StepLabel>Basic</StepLabel>
          </Step>
          <Step>
            <StepLabel>Stations</StepLabel>
          </Step>
          <Step>
            <StepLabel>Route(s) & Fare</StepLabel>
          </Step>
        </Stepper>
      </Container>
      {changeForm()}
    </Sidebar>
  );
};
