import {
  Box,
  Table,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Typography,
  CircularProgress,
  Switch,
  Dialog,
  DialogActions,
  TextField,
} from "@mui/material";
import { Visibility, Edit, Delete } from "@mui/icons-material";
import { getCollectionData, getDocumentData, getSnapShotData } from "../../firebase/getData";
import { Sidebar } from "../../components/Sidebar";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { addDocument, createDocument, updateDocument } from "../../firebase/setData";
import { deleteDocument } from "../../firebase/deleteData";
import { AddNewCity } from "./AddNewCity";



import { AddBusNumber } from "./AddBusNumber";
import { AddNewHostDriver } from "./AddNewHostDriver";
import { CopyRoute } from "./CopyRoute";
// import { AddBusNumber } from "./EditRouteSteps/AddBusNumber";
// import { AddNewHostDriver } from "./EditRouteSteps/AddNewHostDriver";

export const BusRoutes = () => {
  const [arr, setArr] = useState([]);
  let [noData, setNoData] = useState(false);
  console.log(arr, "okokokokkok");
  useEffect(() => {

    getData()

  }, []);

  function getData() {
    getCollectionData("routes")
      .then((res) => {
        setArr(res);
        res.length == 0 ? setNoData(true) : null;
      })
      .catch((err) => console.log(err));

  }

  let [deleteDialog, setDeleteDialog] = useState(false);
  let [delId, setDelId] = useState("");
  let [deletedIndex, setDeletedIndex] = useState(0);



  function moveToDelete(id, index) {
    setDeleteDialog(true);
    setDelId(id);
    setDeletedIndex(index);
  }

  async function deleteRoute() {
    setDeleteDialog(false);
    console.log(deletedIndex);
    let arr2 = [...arr];
    arr2.splice(deletedIndex, 1);
    setArr(arr2);
    await deleteDocument("routes", delId).catch((err) => console.log(err));
  }



  const toggleChecked = (event, id, index) => {
    let newArr = [...arr];

    newArr[index].isActive = event.target.checked;
    setArr(newArr);

    updateDocument("routes", id, {
      isActive: event.target.checked,
    })
      .then(() => { })
      .catch((err) => {
        alert(err);
      });
  };
  function copyRoute(e, index) {
    console.log(e)
    console.log(index)
  }

  if (arr.length == 0) {
    return (
      <Sidebar>
        <Box sx={{ my: 4 }}>
          <Link to="/route/create-route">
            <Button variant="contained">+ Create Route</Button>
          </Link>
          <AddNewCity />
          <AddBusNumber />
          <AddNewHostDriver />

        </Box>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {noData ? <div>No Data Available</div> : <CircularProgress />}
        </Box>
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <Dialog open={deleteDialog}>
        <Typography sx={{ p: 5 }}>Are you sure want to delete ?</Typography>

        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button autoFocus onClick={() => setDeleteDialog(false)}>
            Cancel
          </Button>
          <button className="btn-primary" onClick={deleteRoute} autoFocus>
            Delete
          </button>
        </DialogActions>
      </Dialog>

      <Box sx={{ textAlign: "left", m: 0, width: "100%", my: 4 }}>
        <Link to="/route/create-route">
          <Button variant="contained" color="primary">
            + Create Route
          </Button>
        </Link>

        <AddNewCity />
        <AddBusNumber />
        <AddNewHostDriver />
      </Box>
      <br />
      <br />
      {arr.length === 0 ? (
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>S.No</b>
              </TableCell>
              <TableCell>
                <b>Journey</b>
              </TableCell>
              <TableCell>
                <b>Bus Number</b>
              </TableCell>
              <TableCell>
                <b>Agency</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
              <TableCell>
                <b>Copy Route</b>
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {arr &&
              arr.map((e, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}.</TableCell>
                    <TableCell>
                      <Typography textTransform="capitalize">
                        {e.from} - {e.to}
                      </Typography>
                    </TableCell>
                    <TableCell>{e.busNumber.map((bus, index2) => <span> {bus} {index2 != e.busNumber.length - 1 ? "," : null} </span>)}</TableCell>
                    <TableCell>{e.agency}</TableCell>
                    <TableCell>
                      <div>
                        {e.isActive ? (
                          <span style={{ color: "#1fd655" }}>Active</span>
                        ) : (
                          <span style={{ color: "red" }}>Inactive</span>
                        )}
                      </div>

                      <Switch
                        checked={e.isActive}
                        onChange={(event) => toggleChecked(event, e.id, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`./view-route/${e.id}`}>
                        <IconButton>
                          <Visibility color="primary" />
                        </IconButton>
                      </Link>
                      &nbsp;
                      <Link to={`./edit-route/${e.id}`}>
                        <IconButton>
                          <Edit color="primary" />
                        </IconButton>
                      </Link>
                      &nbsp;
                      <IconButton onClick={() => moveToDelete(e.id, index)}>
                        <Delete color="primary" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <CopyRoute getData={getData} routeData={e} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      )}


    </Sidebar>
  )
}
