import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { getDocumentData } from "../../firebase/getData";
import { createDocument, updateDocument } from "../../firebase/setData";

export const AddBusNumber = () => {
  let [allBusNumbers, setAllBusNumbers] = useState([]);
  let [addBusNumberDialog, setAddBusNumberDialog] = useState(false);
  let [busNumber, setBusNumber] = useState("");

  useEffect(() => {
    getAllBusNumbers();
  }, []);

  function getAllBusNumbers() {
    getDocumentData("allBusNumbers", "allBusNumbersList").then((res) => {
      console.log(res);
      if (res != undefined) {
        setAllBusNumbers([...res.busNumbers]);
      }
    });
  }

  function addBusNumber(e) {
    e.preventDefault();
    let num = 0;
    for (let i = 0; i < allBusNumbers.length; i++) {
      if (
        allBusNumbers[i].toLowerCase().trim() == busNumber.toLowerCase().trim()
      ) {
        console.log("already exist");
        num++;
        break;
      }
    }

    if (num == 0) {
      if (allBusNumbers.length == 0) {
        createDocument("allBusNumbers", "allBusNumbersList", {
          busNumbers: [busNumber.trim()],
        });
        getAllBusNumbers();
        setBusNumber("");
        setAddBusNumberDialog(false);
      } else {
        updateDocument("allBusNumbers", "allBusNumbersList", {
          busNumbers: [...allBusNumbers, busNumber.trim()],
        });
        console.log("i am update");
        getAllBusNumbers();
        setBusNumber("");
        setAddBusNumberDialog(false);
      }
    } else {
      alert("Bus Number is already exist");
      setBusNumber("");
    }
  }
  function moveToDeleteBusNumber(e, index) {
    let arr = [...allBusNumbers];
    arr.splice(index, 1);
    setAllBusNumbers(arr);

    updateDocument("allBusNumbers", "allBusNumbersList", {
      busNumbers: arr,
    });
  }
  return (
    <>
      <Button
        onClick={() => setAddBusNumberDialog(true)}
        sx={{ m: 2 }}
        variant="contained"
        color="primary"
      >
        + Add Bus Number
      </Button>

      <Dialog open={addBusNumberDialog}>
        <Box sx={{ height: "100vh" }}>
          <form onSubmit={addBusNumber}>
            <Box sx={{ p: 3 }}>
            <Typography sx={{mx:40}}></Typography>
              <Typography variant="h5" sx={{ textAlign: "center"}}>
                Add Bus Number
              </Typography>
              <TextField
                onChange={(e) => e.target.value==' '?e.target.value="":setBusNumber(e.target.value)}
                value={busNumber}
                required
                label="Type Bus Number"
                sx={{ m: 3, width: "90%" }}
              />
              <br />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => setAddBusNumberDialog(false)}
                  sx={{ mx: 2 }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Add Bus Number
                </Button>
              </Box>
            </Box>
          </form>

          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {" "}
            All Bus Numbers
          </Typography>
          <Box sx={{ height: "50vh", overflowY: "scroll", px: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Sno.</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allBusNumbers &&
                  allBusNumbers.map((e, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>{e}</TableCell>
                        <TableCell>
                          <Delete
                            onClick={() => moveToDeleteBusNumber(e, index)}
                            sx={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
