import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";

export const Thirdstep = ({ thirdfun, allData, previousStep, dd, allCty }) => {
  let [all, setAll] = useState({});
  let [showInput, setShowInput] = useState(false);
  let [data, setData] = useState({ from: "", to: "", fare: "" });
  useEffect(() => {
    setAll(allData);
  }, []);
  function handleSubmit() {
    thirdfun(all);
  }

  function submitData() {
    console.log(data, "kokokkok");
    console.log(all, "kokokkok");
    let arr = { ...all };
    arr.routes = [...arr.routes, `${data.from}-${data.to}`];
    arr.routesWithFare = [
      ...arr.routesWithFare,
      { fare: data.fare, destination: `${data.from}-${data.to}` },
    ];
    setAll(arr);
    setData({ from: "", to: "", fare: "" });
    setShowInput(false);
  }
  function moveToDelete(index) {
    let arr = { ...all };

    arr.routes.splice(index, 1);
    arr.routesWithFare.splice(index, 1);

    setAll(arr);
  }
  function movoToPrevious() {
    dd(all);
    previousStep();
  }

  return (
    <>
      <Container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>S.No.</b>
              </TableCell>
              <TableCell>
                <b>Journey</b>
              </TableCell>
              <TableCell>
                <b>Fare</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {all.routesWithFare &&
              all.routesWithFare.map((e, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}.</TableCell>
                    <TableCell>
                      <Typography textTransform="capitalize">
                        {e.destination}
                      </Typography>
                    </TableCell>
                    <TableCell>{e.fare}</TableCell>
                    <TableCell>
                      <Delete
                        sx={{ cursor: "pointer" }}
                        color="primary"
                        onClick={() => moveToDelete(index)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        {!showInput ? (
          <Button
            variant="text"
            color="primary"
            onClick={() => setShowInput(true)}
          >
            + Add Station
          </Button>
        ) : (
          <form onSubmit={submitData}>
            <Grid
              container
              sx={{ display: "flex", alignItems: "center" }}
              spacing={1}
            >
              <Grid item md={3}>
                <Autocomplete
                  options={allCty}
                  defaultValue={data.from}
                  onChange={(e, val) => setData({ ...data, from: val })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="From"
                      placeholder="Enter Source"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <Autocomplete
                  options={allCty}
                  defaultValue={data.to}
                  onChange={(e, val) => setData({ ...data, to: val })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="To"
                      placeholder="Enter Source"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  defaultValue={data.fare}
                  label="Fair"
                  type="number"
                  onChange={(e) => setData({ ...data, fare: e.target.value })}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item md={3}>
                <Button variant="contained" type="submit">
                  Save
                </Button>
                <Button onClick={() => setShowInput(false)}>Cancel</Button>
              </Grid>
            </Grid>
          </form>
        )}

        <br />
        <br />
        <Container>
          <Grid container spacing={4}>
            <Grid item md={2}></Grid>
            <Grid item md={4}>
              <Button
                onClick={movoToPrevious}
                color="secondary"
                variant="contained"
                fullWidth
              >
                Previous
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
              >
                Finish
              </Button>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};
