import { TableCell, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { deleteDocument } from "../../firebase/deleteData";

const handleDelete = (docId) => {
  if (confirm("Are You Sure?") == true) {
    deleteDocument("users", docId)
      .then(() => window.location.reload())
      .catch((err) => console.log(err));
  }
};

export const UserTableData = ({ id, index, name, phone, mail, gender }) => {
  return (
    <>
      <TableCell>{index}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{mail}</TableCell>
      <TableCell>{gender}</TableCell>
      <TableCell>
        <IconButton onClick={() => handleDelete(id)}>
          <Delete color="primary" />
        </IconButton>
      </TableCell>
    </>
  );
};
