import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Sidebar } from "../../components/Sidebar";
import { TableHead } from "@mui/material";
import { getCollectionData } from "../../firebase/getData";
import { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";

export function BookingReport() {
  let [allData, setAllData] = useState([]);
  let [noData, setNoData] = useState(false);
  useEffect((e) => {
    getAll();
  }, []);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getAll() {
    await getCollectionData("users").then((e) => {
      getAllRoutes(e)
      // console.log(e)
      e.length == 0 ? setNoData(true) : null;
    });
  }

  async function getAllRoutes(users) {
    await getCollectionData("bookings").then((e) => {
      let arr = []
      e.forEach((res) => {
        arr.push({ ...res, customerName: users[users.findIndex(t => t.id == res.userId)].name })
      })
      console.log(arr)

      arr.sort((a, b) => {
        return (
          new Date(b.booking_date.split('-').reverse().join('-')) - new Date(a.booking_date.split('-').reverse().join('-'))
        )
      })

      setAllData(arr);
      e.length == 0 ? setNoData(true) : null;
    });
  }

  if (allData.length == 0) {
    return (
      <Sidebar>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {noData ? <div>No Data Available</div> : <CircularProgress />}
        </Box>

      </Sidebar>
    )
  }

  return (
    <Sidebar>
      <div style={{ marginTop: "3%", marginBottom: "1%", fontSize: "25px" }} variant="contained"><b>BOOKING REPORT</b></div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell><b>From</b></TableCell>
              <TableCell><b>To</b></TableCell>
              <TableCell><b>Agency</b></TableCell>

              <TableCell><b>Mobile</b></TableCell>
              <TableCell><b>Bus No.</b></TableCell>
              <TableCell><b>Customer Name</b></TableCell>

              <TableCell><b>Seat</b></TableCell>
              <TableCell><b>Price</b></TableCell>
              <TableCell><b>Time</b></TableCell>
              <TableCell><b>Date</b></TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? allData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : allData
            ).map((row, index) => (
              <TableRow key={index} style={{ textTransform: "capitalize" }}>
                <TableCell component="th" scope="row">
                  {row.source}
                </TableCell>
                <TableCell  >
                  {row.destination}
                </TableCell>
                <TableCell>
                  {row.travel}
                </TableCell>
                <TableCell>
                  {row.phone}
                </TableCell>
                <TableCell>
                  {row.busNumber}
                </TableCell>
                <TableCell>
                  {row.customerName}
                </TableCell>
                <TableCell>
                  {row.seat}
                </TableCell>
                <TableCell>
                  {row.price}
                </TableCell>
                <TableCell>
                  {row.eta}
                </TableCell>
                <TableCell>
                  {row.booking_date}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={allData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Sidebar>
  );
}
