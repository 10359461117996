import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { allCities } from "../../../data/cities";
import { getCollectionData } from "../../../firebase/getData";

export const Firststep = ({ firstfun, allData, nextStep, allCty }) => {
  let navigate = useNavigate();
  let [newData, setNewData] = useState({});

  let refData = useRef({});
  useEffect(() => {
    setNewData(allData);
    getAllBusNumber();
  }, []);

  let [busNumbers, setBusNumbers] = useState([]);
  function getAllBusNumber() {
    getCollectionData("allBusNumbers").then((e) => {
      setBusNumbers(e[0].busNumbers);
    });
  }

  const handleChange = (event) => {
    console.log(event.target.value, "okokokok");
    setNewData({ ...newData, busNumber: event.target.value });
  };

  function handleSubmit(e) {
    e.preventDefault();
    firstfun(newData);
    nextStep();
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Autocomplete
              defaultValue={allData.from}
              options={allCty}
              onChange={(e, val) => setNewData({ ...newData, from: val })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="From"
                  placeholder="Enter Source"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              defaultValue={allData.to}
              options={allCty}
              onChange={(e, val) => setNewData({ ...newData, to: val })}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="To"
                  placeholder="Enter Destination"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              type="text"
              label="Travel Agency"
              placeholder="Enter Travels Name"
              defaultValue={allData.agency}
              onChange={(e) =>
                e.target.value == " "
                  ? (e.target.value = "")
                  : setNewData({ ...newData, agency: e.target.value })
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                defaultValue={allData.category}
                required
                onChange={(e) =>
                  setNewData({ ...newData, category: e.target.value })
                }
              >
                <MenuItem value="Economy">Economy</MenuItem>
                <MenuItem value="Affordable">Affordable</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>


            {newData.busNumber != undefined ? (
              <Select
                sx={{ width: "100%" }}
                className="inpClass"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={newData.busNumber}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {busNumbers.map((busNumber, index) => (
                  <MenuItem key={index} value={busNumber}>
                    <Checkbox
                      checked={newData.busNumber.indexOf(busNumber) > -1}
                    />
                    <ListItemText primary={busNumber} />
                  </MenuItem>
                ))}
              </Select>
            ) : null}
            {/* <TextField
              label="Bus Number"
              placeholder="Enter Bus Number"
              defaultValue={allData.busNumber}
              fullWidth
              onChange={(e) =>
                e.target.value == " "
                  ? (e.target.value = "")
                  : setNewData({ ...newData, busNumber: e.target.value })
              }
              required
            /> */}
            {/* <Autocomplete
              defaultValue={allData.busNumber}
              options={busNumbers}
              onChange={(e, val) => {
                setNewData({ ...newData, busNumber: val });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter bus number"
                  placeholder="Enter bus number"
                  fullWidth
                  // required
                />
              )}
            /> */}
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <InputLabel>Bus Type</InputLabel>
              <Select
                label="Bus Type"
                defaultValue={allData.type}
                required
                onChange={(e) =>
                  setNewData({ ...newData, type: e.target.value })
                }
              >
                <MenuItem value="2 + 2">2 + 2</MenuItem>
                <MenuItem value="2 + 3">2 + 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <br />
        <Container>
          <Grid container spacing={4}>
            <Grid item md={2}></Grid>
            <Grid item md={4}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => navigate("/route")}
              >
                <b>Cancel</b>
              </Button>
            </Grid>
            <Grid item md={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                <b>Next</b>
              </Button>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </Container>
      </form>
    </>
  );
};
