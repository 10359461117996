import { Delete } from "@mui/icons-material";
import "../../style.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { deleteDocument } from "../../firebase/deleteData";
import { getCollectionData } from "../../firebase/getData";
import { addDocument } from "../../firebase/setData";

export const AddNewHostDriver = () => {
  let [allHostDrivers, setAllHostDrivers] = useState([]);
  let [addHostDriverDialog, setAddHostDriverDialog] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [hostDriver, setHostDriver] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
  });
  let emailRegex = /^\S+@\S+\.\S+$/;
  useEffect(() => {
    getAllHostDrivers();
  }, []);

  function getAllHostDrivers() {
    getCollectionData("bus Operators").then((res) => {
      console.log(res);
      if (res != undefined) {
        setAllHostDrivers(res);
      }
    });
  }

  function addHostDriver(e) {
    e.preventDefault();

    if (!emailError) {
      console.log(hostDriver);
      addDocument("bus Operators", hostDriver);
      getAllHostDrivers();
      setHostDriver({ name: "", address: "", email: "", phone: "" });
    }
  }

  function moveToDeleteBusNumber(e, index) {
    console.log(e);
    deleteDocument("bus Operators", e.id);
    getAllHostDrivers();
  }

  return (
    <>
      <Button
        onClick={() => setAddHostDriverDialog(true)}
        sx={{ m: 2 }}
        variant="contained"
        color="primary"
      >
        + Add Operator
      </Button>

      <Dialog
        open={addHostDriverDialog}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <Box>
          <form onSubmit={addHostDriver}>
            <Box sx={{ p: 3 }}>
              <Typography sx={{ mx: 40 }}></Typography>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Add Operator
              </Typography>

              <Grid container>
                <Grid item md={6}>
                  <TextField
                    onChange={(e) =>
                      e.target.value == " "
                        ? (e.target.value = "")
                        : setHostDriver({ ...hostDriver, name: e.target.value })
                    }
                    value={hostDriver.name}
                    required
                    label="Operator Name"
                    sx={{ m: 3, width: "90%" }}
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    onChange={(e) =>
                      e.target.value == " "
                        ? (e.target.value = "")
                        : setHostDriver({
                            ...hostDriver,
                            address: e.target.value,
                          })
                    }
                    value={hostDriver.address}
                    required
                    label="Type Address"
                    sx={{ m: 3, width: "90%" }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    onChange={(e) => {
                      e.target.value.match(emailRegex) == null
                        ? setEmailError(true)
                        : setEmailError(false);
                      e.target.value == " "
                        ? (e.target.value = "")
                        : setHostDriver({
                            ...hostDriver,
                            email: e.target.value,
                          });
                    }}
                    value={hostDriver.email}
                    required
                    label="Email"
                    sx={{ m: 3, width: "90%" }}
                  />
                  {emailError ? (
                    <Typography
                      sx={{ mx: 3, p: 0 }}
                      variant="subtitle2"
                      className="red_clr"
                    >
                      Please insert a valid email address
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    onChange={(e) =>
                      setHostDriver({ ...hostDriver, phone: e.target.value })
                    }
                    value={hostDriver.phone}
                    required
                    label="Phone Number"
                    type="number"
                    sx={{ m: 3, width: "90%" }}
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => setAddHostDriverDialog(false)}
                  sx={{ mx: 2 }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Add Operator
                </Button>
              </Box>
            </Box>
          </form>

          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {" "}
            All Operators
          </Typography>
          <Box
            sx={{ height: "50vh", overflowY: "scroll", px: 2, width: "100%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Sno.</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>

                  <TableCell>
                    <b>Phone No.</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allHostDrivers &&
                  allHostDrivers.map((e, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.email}</TableCell>

                        <TableCell>{e.phone}</TableCell>
                        <TableCell>
                          <Delete
                            onClick={() => moveToDeleteBusNumber(e, index)}
                            sx={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
