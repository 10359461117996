
import { uuidv4 } from "@firebase/util";
import { ContentCopy } from "@mui/icons-material";
import { Button, Dialog, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { createDocument } from "../../firebase/setData";

export const CopyRoute = ({ routeData, getData }) => {
    const [dialogState, setDialogState] = useState(false)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const id = uuidv4()
    function submitData(e) {
        e.preventDefault()
        let addedMinutes = hours * 60 + minutes * 1
        let newRoute = { ...routeData }
        let subSt = []
        newRoute.id = id
        routeData.subStations.forEach((res) => {
            subSt.push({ ...res, eta: addedTime(res.eta, addedMinutes) })
        })
        newRoute.subStations = [...subSt]

        createDocument("routes", id, { ...newRoute, ["isActive"]: true })
            .then(() => {
                setDialogState(false)
                getData()
                console.log("Data Added")
            })
            .catch((err) => console.log(err));

    }
    function addedTime(time, minsToAdd) {
        function D(J) { return (J < 10 ? '0' : '') + J; };
        var piece = time.split(':');
        var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
        return (D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60))
    }

    return (
        <>
            <Button onClick={() => setDialogState(true)} variant="contained" color="primary">Copy</Button>
            <Dialog
                open={dialogState}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Box p={2}>
                    <form onSubmit={submitData}>
                        <Typography variant="h5" align='center'>Copy Route</Typography>
                        <Typography mx={2} sx={{ fontSize: '15px', ml: 2 }}>Add time :</Typography>
                        <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
                            <input placeholder="00" required type='number' min='0' onChange={(e) => setHours(e.target.value)} style={{ width: '150px', margin: '0px 10px 0px 10px' }} />hr
                            <input placeholder="00" required max='59' min='0' onChange={(e) => setMinutes(e.target.value)} type='number' style={{ width: '150px', margin: '0px 10px 0px 10px' }} />min
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={() => setDialogState(false)} sx={{ mx: 2 }}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Copy
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Dialog>
        </>
    )
}