import {
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Box } from "@mui/system";

import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { getDocumentData } from "../../firebase/getData";
import { updateDocument } from "../../firebase/setData";
import { Firststep } from "./EditRouteSteps/Firststep";
import { Secondstep } from "./EditRouteSteps/Secondstep";
import { Thirdstep } from "./EditRouteSteps/Thirdstep";

export const EditRoute = () => {
  let navigate=useNavigate()
  const { id } = useParams();
  let [steps, setSteps] = useState(1)
  let [allData, setAllData] = useState({})
  let [loader, setLoader] = useState(true)
  let [cities, setCities] = useState([]);

  useEffect(() => {
    getOldRouteData();
    getAllCities();
  }, [])
  const getOldRouteData = () => {
    getDocumentData('routes', id)
      .then((e) => {
        console.log(e)
        setAllData(e)
        setLoader(false)
      })
  }


  function getFirstData(d) {
    // console.log(d)
    setAllData(d)
  }
  function getSecondData(d) {
    console.log(d)
    if(d.subStations.length == 0){
      alert("Please Add Sub Station");
    }
    else{
   nextStep()
    setAllData(d)
    }
 
  }
  function getThirdData(d) {
    console.log(d)
    updateDocument('routes',d.id,d)
    .then(()=>{
      navigate('/route')
    })
  }
  function nextStep() {
    setSteps(steps + 1)
  }
  function previousStep() {
    setSteps(steps - 1)
  }

  function getAllCities() {
    getDocumentData("AllCities", "AllCitiesList").then((res) => {
      setCities([...res.cities]);
    });
  }

  function Mainfuction() {
    switch (steps) {
      case 1:
        return <Firststep firstfun={getFirstData} allData={allData} nextStep={nextStep} allCty={cities}/>;
      case 2:
        return <Secondstep secondfun={getSecondData} allData={allData} previousStep={previousStep} allCty={cities}/>;
      case 3:
        return <Thirdstep thirdfun={getThirdData} allData={allData} previousStep={previousStep} dd={getFirstData} allCty={cities}/>
      default:
    }
  }
  return (
    <>
      <Sidebar>
        {loader ? <CircularProgress /> :
          <Box>
            <Stepper
              activeStep={steps}
              alternativeLabel
              sx={{ marginBottom: "5vh" }}
            >
              <Step>
                <StepLabel>Basic</StepLabel>
              </Step>
              <Step>
                <StepLabel>Stations</StepLabel>
              </Step>
              <Step>
                <StepLabel>Route(s) & Fare</StepLabel>
              </Step>
            </Stepper>
            <Mainfuction />
          </Box>
        }

      </Sidebar>
    </>
  );
};
