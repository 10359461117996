import { Sidebar } from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, filledInputClasses, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getDocumentData } from "../../firebase/getData";
import { Container } from "@mui/system";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ViewRoute = () => {
  let navigate = useNavigate()
  const { id } = useParams();
  let [loader, setLoader] = useState(true)
  let [childState, setChildState] = useState([])
  useEffect(() => {
    getAllData()
  }, [])
  let [allData, setAllData] = useState({})

  function getAllData() {
    getDocumentData('routes', id)
      .then((e) => {
        setAllData(e)
        setLoader(false)
        console.log(e)
      })
  }
  function changeHandler(event, e, index) {
    let dd = allData.subStations.filter((fil) => fil.boarding == event.target.value)
    let rr = [...childState]
    rr[index] = dd[0]
    setChildState(rr)
  }
  return (
    <Sidebar>
      {loader ? <CircularProgress /> :
        <Container>
          <IconButton>
          <ArrowBackIcon onClick={()=>navigate('/route')} />

          </IconButton>

          <div style={{ marginBottom:"1%", fontSize:"25px"}} variant="contained"> <b>DETAILED ROUTE</b></div>
          <Typography variant="h5" sx={{my:3,color:'#002e6e',fontWeight:'700',borderBottom:'2px solid rgba(155,155,155,0.36)'}}>BUS NUMBER- {allData.busNumber}</Typography>
          <Grid container spacing={2} sx={{ my: 3 }}>

            <Grid item md={6} container>
              <Grid item md={3}>
                <label>FROM:</label>
              </Grid>
              <Grid item md={6} className="view_route_border">
                <b>{allData.from}</b>
              </Grid>
            </Grid>

            <Grid item md={6} container>
              <Grid item md={3}>
                <label>TO:</label>
              </Grid>
              <Grid item md={6} className="view_route_border">
                <b>{allData.to}</b>
              </Grid>
            </Grid>



            <Grid item md={6} container>
              <Grid item md={3}>
                <label>TRAVEL AGENCY:</label>
              </Grid>
              <Grid item md={6} className="view_route_border">
                <b>{allData.agency}</b>
              </Grid>
            </Grid>

            <Grid item md={6} container>
              <Grid item lg={3}>
                <label>CATEGORY:</label>
              </Grid>
              <Grid item lg={6} className="view_route_border">
                <b>{allData.category}</b>
              </Grid>
            </Grid>

            <Grid item md={6} container>
              <Grid item md={3}>
                <label>BUS TYPE:</label>
              </Grid>
              <Grid item md={6} className="view_route_border">
                <b>{allData.type}</b>
              </Grid>
            </Grid>



          </Grid>
          <Typography variant="h5">STATION</Typography>

          <Grid container spacing={3} sx={{ my: 3,pb:5 }}>
            {allData.topStation.map((e, index) => {

              return (
                <>
                  <Grid item md={4} container>
                    <Grid item md={2}>
                      <label>{index + 1}.</label>
                    </Grid>
                    <Grid className="view_route_border" item md={8}>
                      <b>{e}</b>
                    </Grid>

                  </Grid>

                  <Grid item md={4} container>
                    <Grid md={4}>
                      <label>PRICE:</label>
                    </Grid>
                    <Grid md={8} className="view_route_border">
                      <b>{allData.stationFare.map((p) => { if (p.station == e) { return p.fare } })}</b>
                    </Grid>

                  </Grid>
                  <Grid item md={4}></Grid>
                  <Grid item md={4}></Grid>

                  <Grid item md={4} container>
                    {/* <Grid item md={4}></Grid> */}
                    <Grid item md={4}>
                      <label>Boarding Point: </label>
                    </Grid>
                    <Grid item md={8}>
                      <select className="select_cls" onChange={(event) => changeHandler(event, e, index)}>
                        <option selected disabled>select Boarding point</option>
                        {allData.subStations.map((c) => {
                          if (c.name == e) {

                            return (
                              <option value={c.boarding}>{c.boarding}</option>
                            )
                          }
                        })}
                      </select>
                    </Grid>
                  </Grid>

                  <Grid item md={4} container>
                    <Grid item md={3}>
                      <label>Time:</label>
                    </Grid>
                    <Grid item md={8} className="view_route_border">
                      <b>{childState[index] != undefined ? childState[index].eta : ''}</b>
                    </Grid>
                  </Grid>

                  <Grid md={4}></Grid>

                  <Grid item md={4} container>
                    <Grid item md={4}>
                      <label>Latitude:</label>
                    </Grid>
                    <Grid item md={8} className="view_route_border">
                      <b>{childState[index] != undefined ? childState[index].lat : ''}</b>
                    </Grid>
                  </Grid>

                  <Grid item md={4} container>
                    <Grid item md={3}>
                      <label>Longitude:</label>
                    </Grid>
                    <Grid item md={8} className="view_route_border">
                      <b>{childState[index] != undefined ? childState[index].long : ''}</b>
                    </Grid>
                  </Grid>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>
              )
            })
            }
          </Grid>

        </Container>
      }

    </Sidebar>
  );
};
