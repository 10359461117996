import { async } from "@firebase/util";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { arrayRemove } from "firebase/firestore";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { getCollectionData, getDocumentData } from "../../firebase/getData";
import { updateDocument } from "../../firebase/setData";

export const EditHostDriver = () => {
  let { id } = useParams();
  let [allData, setAllData] = useState({});
  let [submit, setSubmit] = useState(false);
  let [allBusOperators, setAllBusOperators] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    getDriver();
    getAllBusNumber();
    getAllBusOperator();
  }, []);

  function getAllBusOperator() {
    getCollectionData("bus Operators").then((res) => {
      console.log(res);
      setAllBusOperators(res);
      let arr=[]
      res.map(e=>arr.push(e.name))
      setAllBusOperators(arr)
    });
  }

  async function getDriver() {
    getDocumentData("hostUserDriver", id).then((e) => {
      console.log(e);
      setAllData(e);
    });
  }

  //get all bus number

  let [busNumbers, setBusNumbers] = useState([]);
  function getAllBusNumber() {
    getCollectionData("allBusNumbers").then((e) => {
      setBusNumbers(e[0].busNumbers);
    });
  }

  const handleChange = (event) => {
    console.log(event.target.value, "okokokok");
    setAllData({ ...allData, busNumber: event.target.value });
  };

  async function editHost(e) {
    setSubmit(true);
    e.preventDefault();
    updateDocument("hostUserDriver", id, allData).then((e) => {
      console.log("data updated");
      setAllData({});
      navigate("/host-driver");
    });
  }
  console.log(allData.busNumber, "all bus numbers are here");

  if (allData.busOperatorName == undefined) {
    return (
      <Sidebar>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Sidebar>
    );
  }
  if (submit) {
    return (
      <Sidebar>
        <Box
          sx={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Sidebar>
    );
  } else {
    return (
      <Sidebar>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Edit Host Driver
        </Typography>
        <Box Validate component="form" onSubmit={editHost}>
          <Grid container sx={{ width: "60%", margin: "auto" }} spacing={3}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
              }}
            >
              <label className="labelCls">
                <b>
                  Host Name<sup>*</sup> :{" "}
                </b>
              </label>
              <input
                className="inpClass"
                required
                defaultValue={allData.hostName}
                onChange={(e) =>
                  e.target.value == " "
                    ? (e.target.value = "")
                    : setAllData({ ...allData, ["hostName"]: e.target.value })
                }
              />
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
              }}
            >
              <label className="labelCls">
                {" "}
                <b>
                  Phone no<sup>*</sup> :{" "}
                </b>
              </label>
              <input
                className="inpClass"
                type="number"
                required
                defaultValue={allData.phoneNo}
                onChange={(e) =>
                  setAllData({ ...allData, ["phoneNo"]: e.target.value })
                }
              />
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
              }}
            >
              <label className="labelCls">
                {" "}
                <b>
                  Bus Number<sup>*</sup> :{" "}
                </b>
              </label>
              {/* <input
                className="inpClass"
                required
                defaultValue={allData.busNumber}
                onChange={(e) =>
                  e.target.value == " "
                  ?(e.target.value  = "")
                  :setAllData({ ...allData, ["busNumber"]: e.target.value })
                }
              /> */}

              <Select
                className="inpClass"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={allData.busNumber}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
              >
                {busNumbers.map((busNumber, index) => (
                  <MenuItem key={index} value={busNumber}>
                    <Checkbox
                      checked={allData.busNumber.indexOf(busNumber) > -1}
                    />
                    <ListItemText primary={busNumber} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
              }}
            >
              <label className="labelCls">
                {" "}
                <b>
                  Bus Type<sup>*</sup> :{" "}
                </b>
              </label>
              <Select
                className="inpClass"
                required
                defaultValue={allData.busType}
                onChange={(e) =>
                  setAllData({ ...allData, ["busType"]: e.target.value })
                }
              >
                <MenuItem value="2 + 2">2 + 2</MenuItem>
                <MenuItem value="2 + 3">2 + 3</MenuItem>
              </Select>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                alignItems: "center",
              }}
            >
              <label className="labelCls">
                {" "}
                <b>
                  Bus Operator Name<sup>*</sup> :{" "}
                </b>
              </label>

              <Autocomplete
                className="inpClass"
                defaultValue={allData.busOperatorName}
                onChange={(e, val) => {
                  setAllData({
                    ...allData,
                    ["busOperatorName"]: val,
                  });
                }}
                options={allBusOperators}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Operator"
                    fullWidth
                    required
                  />
                )}
              />

              {/* <input
                className="inpClass"
                required
                defaultValue={allData.busOperatorName.name}
                onChange={(e) =>
                  e.target.value == " "
                    ? (e.target.value = "")
                    : setAllData({
                        ...allData,
                        ["busOperatorName"]: e.target.value,
                      })
                }
              /> */}
            </Grid>
          </Grid>

          <Container maxWidth="xs" sx={{ mt: 5 }}>
            <Grid container spacing={2}>
              <Grid item md={1}></Grid>
              <Grid item md={5}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Update
                </Button>
              </Grid>
              <Grid item md={5}>
                <Link to="/host-driver">
                  <Button variant="contained" color="secondary" fullWidth>
                    <b>Cancel</b>
                  </Button>
                </Link>
              </Grid>
              <Grid item md={1}></Grid>
            </Grid>
          </Container>
        </Box>
      </Sidebar>
    );
  }
};
