import { collection, addDoc, setDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "./firebase";

// Add Document to a Collection
export const addDocument = async (collectionName, data) => {
  await addDoc(collection(db, collectionName), {
    ...data,
  }).catch((err) => console.log(err));
};

// Create a Document with DocId
export const createDocument = async (collectionName, docId, data) => {
  await setDoc(doc(db, collectionName, docId), {
    ...data,
  });
};

// Update a document to a Collection
export const updateDocument = async (collectionName, docId, data) => {
  await updateDoc(doc(db, collectionName, docId), {
    ...data,
  });
};
