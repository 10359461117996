import { Add, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";

export const Secondstep = ({ secondfun, allData, previousStep, allCty }) => {
  let [modalOne, setModalOne] = useState(false);
  let [modalTwo, setModalTwo] = useState(false);
  let [modalOneData, setModalOneData] = useState({});
  let [modalTwoData, setModalTwoData] = useState({});
  let [all, setAll] = useState({});

  useEffect(() => {
    setAll(allData);
  }, []);
  function modalOneSubmit(e) {
    e.preventDefault();
    console.log(modalOneData);
    console.log(all);
    setAll({
      ...all,
      topStation: [...all.topStation, modalOneData.topStation],
      stationFare: [...all.stationFare, modalOneData.stationFare],
    });
    setModalOne(false);
  }
  let [subParent, setSubParent] = useState("");
  function moveToAddSubstation(r) {
    setSubParent(r);
    setModalTwo(true);
  }
  function addSubstation(e) {
    e.preventDefault();
    console.log(modalTwoData);
    setAll({
      ...all,
      subStations: [...all.subStations, { ...modalTwoData, name: subParent }],
    });
    setModalTwo(false);
    setModalTwoData({})
  }
  function handlesubmit() {
    secondfun(all);
  }
  function moveToDelete(e, index) {
    let arr = { ...all };
    arr.topStation.splice(index, 1);
    arr.subStations = arr.subStations.filter((c) => c.name !== e);
    arr.stationFare = arr.stationFare.filter((f) => f.station !== e)
    console.log(e, index);
    console.log(arr);
    setAll({ ...arr });
  }
  function moveToDeleteSubstation(el, inx) {
    let arr = { ...all };
    arr.subStations = arr.subStations.filter((r) => r !== el)
    setAll(arr);
  }
  return (
    <>
      <Container>
        <Button
          variant="text"
          color="primary"
          onClick={() => setModalOne(true)}
        >
          + Add Station
        </Button>

        <Modal open={modalOne} onClose={() => setModalOne(false)}>
          <Container
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 5,
                width: "80%",
                borderRadius: 1,
                textAlign: "center",
                backgroundColor: "white",
              }}
            >
              <Typography>
                <b>Add Station Location</b>
              </Typography>
              <hr />
              <br />
              <form onSubmit={modalOneSubmit}>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <Autocomplete
                      // value={station}
                      onChange={(e, val) =>
                        setModalOneData({
                          ...modalOneData,
                          topStation: val,
                          stationFare: {
                            ...modalOneData.stationFare,
                            station: val, fare: ''
                          },
                        })
                      }
                      options={allCty}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Station"
                          placeholder="Enter Station Location"
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item md={6}>
                    <TextField
                      label="Fare"
                      type="number"
                      placeholder="Enter Fare Price"
                      // value={fare}
                      onChange={(e) =>
                        setModalOneData({
                          ...modalOneData,
                          stationFare: {
                            ...modalOneData.stationFare,
                            fare: e.target.value,
                          },
                        })
                      }
                      required
                      fullWidth
                    />
                  </Grid> */}
                </Grid>
                <br />
                <Grid container spacing={4}>
                  <Grid item md={2}></Grid>
                  <Grid item md={4}>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={() => setModalOne(false)}
                    >
                      <b>Cancel</b>
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      type="submit"
                    >
                      <b>Submit</b>
                    </Button>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Modal>
        <br />
        <br />
        {all.topStation &&
          all.topStation.map((e, index) => {
            return (
              <Box key={index}>
                <Grid container spacing={4}>
                  <Grid item md={4}>
                    <b>{index + 1}.</b>
                  </Grid>
                  <Grid item md={4}>
                    <b>{e}</b>
                  </Grid>
                  <Grid item md={4}>
                    <IconButton onClick={() => moveToAddSubstation(e)}>
                      <Add color="primary" />
                    </IconButton>
                    <IconButton>
                      <Delete
                        color="primary"
                        onClick={() => {
                          moveToDelete(e, index);
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
                <br />
                {all.subStations &&
                  all.subStations
                    .filter((item) => e === item.name)
                    .map((el, inx) => {
                      console.log(inx)
                      return (
                        <Grid container spacing={4} key={inx}>
                          <Grid item md={4}></Grid>
                          <Grid item md={4}>
                            {el.boarding}
                          </Grid>
                          <Grid item md={4}>
                            {el.eta}
                            <IconButton>
                              <Delete
                                color="primary"
                                onClick={() => {
                                  moveToDeleteSubstation(el, inx);
                                }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                <hr />
              </Box>
            );
          })}
        <Modal open={modalTwo} onClose={() => setModalTwo(!modalTwo)}>
          <Container
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                p: 5,
                width: "80%",
                borderRadius: 1,
                textAlign: "center",
                backgroundColor: "white",
              }}
            >
              <Typography>
                <b>Select Boarding Points</b>
              </Typography>
              <hr />
              <br />
              <form onSubmit={addSubstation}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <br />
                    <TextField
                      label="Boarding Point"
                      placeholder="Enter Boarding Point"
                      value={modalTwoData.boarding}
                      onChange={(e) =>
                        e.target.value == " "
                          ? (e.target.value = "")
                          : setModalTwoData({
                            ...modalTwoData,
                            boarding: e.target.value,
                          })
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={6} sx={{ textAlign: "left" }}>
                    <Typography sx={{ mb: 0.25 }}>Choose Time</Typography>
                    <TextField
                      type="time"
                      value={modalTwoData.eta}
                      fullWidth
                      required
                      onChange={(e) =>
                        setModalTwoData({
                          ...modalTwoData,
                          eta: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      type="number"
                      label="Latitude"
                      placeholder="Enter Latitude"
                      value={modalTwoData.lat}
                      fullWidth
                      required
                      onChange={(e) =>
                        setModalTwoData({
                          ...modalTwoData,
                          lat: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      type="number"
                      label="Longitude"
                      placeholder="Enter Longitude"
                      value={modalTwoData.long}
                      onChange={(e) =>
                        setModalTwoData({
                          ...modalTwoData,
                          long: e.target.value,
                        })
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={6}>
                    {/* <TextField
                      type="date"
                      onChange={(e) =>
                        setModalTwoData({
                          ...modalTwoData,
                          date: e.target.value,
                        })
                      }
                      fullWidth
                      required
                    /> */}
                    <Select
                      fullWidth
                      onChange={(e) => setModalTwoData({
                        ...modalTwoData,
                        day: e.target.value,
                      })}
                      fullwidth

                    >
                      <MenuItem value='Day 1'>Day 1</MenuItem>
                      <MenuItem value='Day 2'>Day 2</MenuItem>
                      <MenuItem value='Day 3'>Day 3</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={4}>
                  <Grid item md={2}></Grid>
                  <Grid item md={4}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => setModalTwo(!modalTwo)}
                      fullWidth
                    >
                      <b>Cancel</b>
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      <b>Submit</b>
                    </Button>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Modal>
        <br />
        <br />
        <Grid container spacing={4}>
          <Grid item md={2}></Grid>
          <Grid item md={4}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={previousStep}
            >
              <b>Previous</b>
            </Button>
          </Grid>
          <Grid item md={4}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handlesubmit}
            >
              <b>Next</b>
            </Button>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
      </Container>
    </>
  );
};
