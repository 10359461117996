import { useState, createContext, useContext } from "react";

// Context created here
const Context = createContext({});

// Context provider created below
export function ContextProvider({ children }) {
  const [step, setStep] = useState(1);

  class Update {
    constructor() {
      this.ResetStep = () => setStep(1);
      this.NextStep = () => setStep(step + 1);
      this.previousStep = () => setStep(step - 1)
    }
  }

  return (
    <Context.Provider value={{ step, Update }}>{children}</Context.Provider>
  );
}

// Custom hook to update context data
export function UpdateContext() {
  const { Update } = useContext(Context);
  const Updater = new Update();

  return Updater;
}

// Custom hook to read context data
export function ReadContext() {
  const { step } = useContext(Context);

  const value = { step };

  return value;
}
